<template>
  <a-modal v-model:visible="visible" footer="" width="50%">
    <template #title>
      <div style="display: flex;justify-content: space-between;padding-right:50px">
        <div>批量添加任务</div>
        <a-button type="primary" @click="confirmAdd" :loading="confirmLoading">确认编辑</a-button>
      </div>
    </template>
    <div style="display: flex;justify-content: space-between;margin-bottom:10px">
      <a-space>
        <a-month-picker :disabled-date="disabledDate" :getCalendarContainer="e => e.parentNode" v-model:value="searchForm.dateTime" placeholder="年/月" @change="monthChange" :allowClear="false" />
        <!-- <a-button @click="searchData">查询</a-button> -->
        <a-select :allowClear="true" v-model:value="orgName" style="width: 180px"  @change="selectChange" placeholder="机构">
          <a-select-option v-for="opt in uniqueList" :key="opt.orgName" :value="opt.orgName">{{ opt.orgName}}</a-select-option>
        </a-select>
      </a-space>

      <div>
        <a-button class="add-btn" @click="addShow = true">新增员工</a-button>
      </div>
    </div>
    <div>
      <a-table size="small" :columns="columns" :rowKey="(record, index) => { return index }" :data-source="listData" bordered :pagination="false" :loading="loading" @change="pageChange">
        <template #taskNum="{ record }">
          <div>
            <a-input style="height: 25px!important;" v-model:value="record.taskNum" />
          </div>
        </template>
        <template #profit="{ record }">
          <div>
            <a-input style="height: 25px;" v-model:value="record.profit" />
          </div>
        </template>
        <template #operate="{ record }">
          <div>
            <a-popconfirm title="确定删除吗" ok-text="确认" cancel-text="取消" @confirm="deleteData(record)" @cancel="cancel">
              <a>删除</a>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
    </div>
  </a-modal>
  <a-modal v-model:visible="addShow" title="新增员工" @ok="addEmp">
    <a-form ref="addFormRef" :model="addForm" :rules="rules" :label-col="{ span: 100 }" :wrapper-col="{ span: '100%' }" layout="vertical">
      <a-form-item label="员工选择" name="empId">
        <a-select v-if="addShow" :filter-option="false" show-search placeholder="搜索并选择" @search="employerValue">
          <template v-if="flagLoading" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="item in employeeOptions" :key="item.id" :value="item.name" @click="employeeChoose(item)">{{ item.name }}-{{ item.orgName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="任务量" name="taskNum">
        <a-input v-model:value="addForm.taskNum" type="number" />
      </a-form-item>
      <a-form-item label="目标利润(元)" v-if="takeType == 0" name="profit">
        <a-input v-model:value="addForm.profit" type="number" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import moment from 'moment'
import { reactive, toRefs, onMounted, ref, computed } from 'vue'
import { getEmpTaskList, addBatchEmpTask, addBatchEmpTaskMin, deleteEmpTask } from '@/api/statistics/batchAdd'
import { employeeList } from '@/api/crmManagement/setRules'
import { getTimeYMD } from '@/utils/util'
import { message } from 'ant-design-vue'
import { cloneDeep } from 'lodash-es'

export default {
  props: {
    type: Number
  },
  setup (props) {
    const addFormRef = ref(null)
    const state = reactive({
      orgName: null,
      mirrorList: [],
      visible: false,
      loading: false,
      addShow: false,
      flagLoading: false,
      confirmLoading: false,
      takeType: 0,
      timer: null,
      searchForm: {
        dateTime: null
      },
      addForm: {
        empId: null,
        empName: null,
        profit: null,
        taskNum: null
      },
      rules: {
        empId: { required: true, message: '请选择员工', trigger: 'blur', type: 'string' },
        dateTime: { required: true, message: '请选择月份', trigger: 'blur', type: 'string' },
        taskNum: { required: true, message: '请输入任务量', trigger: 'blur', type: 'string' },
        profit: { required: true, message: '请输入总利润', trigger: 'blur', type: 'string' }
      },
      mirrorAddForm: {},
      employeeOptions: [],
      listData: [],
      columns: []
    })
    onMounted(() => {
      state.mirrorAddForm = JSON.stringify(state.addForm)
    })
    const uniqueList = computed(() => {
      const seen = new Set();
      return state.mirrorList.filter(item => {
        const orgName = item.orgName;
        if (!seen.has(orgName)) {
          seen.add(orgName);
          return true;
        }
        return false;
      });
    });
    const loadData = () => {
      setColumns()
      state.loading = true
      let d = getTimeYMD().split('-')
      if (!state.searchForm.dateTime) state.searchForm.dateTime = d[0] + '-' + d[1] + '-' + '01' + ' ' + '00:00:00'
      getEmpTaskList({
        ...state.searchForm,
        taskType: props.type // 小程序为1
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data
          state.mirrorList = cloneDeep(res.data)
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const searchData = () => {
      loadData()
    }
    const employerValue = e => {
      if (e === '' || e === null) clearTimeout(state.timer)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        }).then(res => {
          if (res.code === 10000) {
            state.employeeOptions = res.data
            state.flagLoading = false
          }
        }).catch(err => { console.log(err) })
      }, 1000)
    }
   const selectChange = (e) => {
    if(!e){
      state.listData = state.mirrorList
      return
    }
    state.listData = state.mirrorList.filter(item => {
      return item.orgName === e
    })
   }
    const employeeChoose = (item) => {
      state.addForm.empId = item.id
      state.addForm.empName = item.name
      state.addForm.orgName = item.orgName
      state.employeeOptions = []
    }
    const monthChange = (e, v) => {
      state.searchForm.dateTime = v + '-' + '01' + ' ' + '00:00:00'
      loadData()
    }
    const addEmp = () => {
      addFormRef.value.validate().then(() => {
        var isEmp = false;
        state.listData.forEach(e => {
          if (e.empId == state.addForm.empId) {
            isEmp = true;
          }
        });
        if (isEmp) {
          message.error('已添加过这个员工请不要重复添加')
        } else {
          state.addShow = false
          state.listData.push(state.addForm)
          state.mirrorList.push(state.addForm)
          setTimeout(() => {
            state.addForm = JSON.parse(state.mirrorAddForm)
          }, 500)
        }

      })
    }
    const deleteData = (record) => {
      let index = state.listData.findIndex(item => item.empId == record.empId)
      state.listData.splice(index, 1)
      state.mirrorList.splice(index, 1)
      deleteEmpTask({
        empTaskId: record.id,
      }).then(res => {
        if (res.code === 10000) {
          message.success('删除成功')
        }
      }).finally(() => { })
    }
    const confirmAdd = () => {
      state.confirmLoading = true
      if (props.type === 0) {
        addBatchEmpTask({
          dateTime: state.searchForm.dateTime,
          empTasks: state.listData,
          taskType: 0
        }).then(res => {
          if (res.code === 10000) {
            message.success('批量添加成功')
            state.visible = false
          }
        }).finally(() => { state.confirmLoading = false })
      } else {
        addBatchEmpTaskMin({
          dateTime: state.searchForm.dateTime,
          empTasks: state.listData,
          taskType: 1
        }).then(res => {
          if (res.code === 10000) {
            message.success('批量添加成功')
            state.visible = false
          }
        }).finally(() => { state.confirmLoading = false })
      }
    }
    const setColumns = () => {
      if (state.takeType == 1) {
        state.columns = [
          {
            title: '机构',
            dataIndex: 'orgName',
            align: 'center'
          },
          {
            title: '员工',
            dataIndex: 'empName',
            align: 'center',
          },
          {
            title: '任务量',
            dataIndex: 'taskNum',
            align: 'center',
            width: 300,
            height: 20,
            slots: { customRender: 'taskNum' }
          },
          {
            title: '操作',
            dataIndex: 'operate',
            align: 'center',
            slots: { customRender: 'operate' }
          },
        ]
      } else {
        state.columns = [
          {
            title: '机构',
            dataIndex: 'orgName',
            align: 'center'
          },
          {
            title: '员工',
            dataIndex: 'empName',
            align: 'center',
            height: 20,
          },
          {
            title: '任务量',
            dataIndex: 'taskNum',
            align: 'center',
            width: 200,
            height: 20,
            slots: { customRender: 'taskNum' }
          },
          {
            title: '目标利润(元)',
            dataIndex: 'profit',
            align: 'center',
            width: 200,
            height: 20,
            slots: { customRender: 'profit' }
          },
          {
            title: '操作',
            dataIndex: 'operate',
            align: 'center',
            height: 20,
            slots: { customRender: 'operate' }
          },
        ]
      }
    }
    const disabledDate = current => {
      let MM = moment().format('YYYY-MM')
      const DD = moment().format('D')
      if (DD > 5 && DD < 20) {
        MM = moment().add(1, 'months').format('YYYY-MM')
      }
      return current && current < moment(MM)
    }
    return {
      ...toRefs(state),
      addFormRef,
      monthChange,
      searchData,
      confirmAdd,
      deleteData,
      employerValue,
      addEmp,
      employeeChoose,
      disabledDate,
      loadData,
      uniqueList,
      selectChange
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-table-row-cell-break-word) {
  padding: 4px !important;
}
</style>